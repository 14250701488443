/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~material-design-icons/iconfont/material-icons.css';

#bmbf
  {    
      background: url(assets/images/bmbf.png);
      height:100px; width: 194px /*width of image*/;  
      background-size: 190px;
      float: right
  }
  #fostat
  {    
      background: url(assets/images/fostat29t.gif) no-repeat;
      height:90px; width: 180px /*width of image*/;  
      background-size: 170px;
    
  }
  html, body {
    height: 100%;
  }

  .color_list_option{
    color: white !important;
  }